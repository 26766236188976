import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { StaticPageQuery } from '../../types/graphql'

import {
  Blanket,
  BlanketTitle,
  BlanketBackground,
  BlanketContent,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { Media } from '../../components/Media'
import { SEO } from '../../components/Seo'
import { WordpressHTML } from '../../components/WordpressHTML'

import * as classes from './static-page.module.scss'

interface Props {
  data: StaticPageQuery
}

export default ({ data }: Props) => (
  <Blanket>
    <SEO title={data.wordpressPage?.title!} />

    <Container>
      <Media
        data={
          data.wordpressPage?.featured_media?.localFile?.childImageSharp
            ?.fluid as FluidObject
        }
        imageClassName={classes.cover}
      />
    </Container>

    <BlanketBackground>
      <Container size={'small'}>
        <BlanketTitle>{data.wordpressPage?.title!}</BlanketTitle>
        <BlanketContent>
          <WordpressHTML content={data.wordpressPage?.content} />
        </BlanketContent>
      </Container>
    </BlanketBackground>
  </Blanket>
)

export const query = graphql`
  query StaticPage($pageId: Int!) {
    wordpressPage(wordpress_id: { eq: $pageId }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
